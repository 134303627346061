import React from 'react';
import { ClaimHeader } from './elems/claim-header/ClaimHeader';
import { ClaimInfo } from './elems/claim-info/ClaimInfo';
import st from './styles.module.css';

export const GeneralClaim = () => {
  return (
    <section className={st.section}>
      <ClaimHeader />
      <h1>Claim portal</h1>
      <ClaimInfo />
      <p>© 2022 - all rights reserved 丨 powered by carmellow invest limited</p>
    </section>
  );
};
