import React, { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import BigNumber from 'bignumber.js';
import jazzicon from '@metamask/jazzicon';
import headerLogo from './header-logo.svg';
import { useEvmWallet } from '../../../../services/EvmWalletService';
import { formatTONAddress } from '../../../../utils/helpers';
import MMIcon from './metamask-icon.svg';
import st from './styles.module.css';

export const ClaimHeader = observer(() => {
  const { isConnected, connect, address, coin } = useEvmWallet();
  const avatarRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const element = avatarRef.current;
    if (!element || !address) return;

    const seed = parseInt(address.slice(2, 10), 16);
    const icon = jazzicon(38, seed);

    if (element.firstChild) {
      element.removeChild(element.firstChild);
    }

    element.appendChild(icon);
  }, [address, avatarRef, isConnected]);

  const tokenBalance = useMemo(
    () => new BigNumber(coin.balance || 0).shiftedBy(-coin.decimals).precision(2).toString(),
    [coin]
  );

  return (
    <div className={st.header}>
      <img src={headerLogo} alt="claim" />
      <button type="button" disabled={isConnected} className={st.button} onClick={!isConnected ? connect : undefined}>
        {!isConnected && (
          <>
            Connect Wallet <img src={MMIcon} alt="" />
          </>
        )}
        {isConnected && (
          <>
            <div>
              <span>
                {coin.icon} {tokenBalance} {coin.symbol}
              </span>
              <span>
                {formatTONAddress({
                  address,
                  numberOfCharactersUpTo: 4,
                  numberOfCharactersAfter: 4,
                })}
              </span>
            </div>
            <div ref={avatarRef} />
          </>
        )}
      </button>
    </div>
  );
});
