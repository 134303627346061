import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useEvmWallet } from '../../../../services/EvmWalletService';
import { AmountInfo } from '../AmountInfo/AmountInfo';
import { claimTokens, useClaimDetails } from './useClaimDetails';
import MMIcon from './metamask-button-icon.svg';
import TokenImage from './token-image.svg';
import PendingIcon from './pending.svg';
import SuccessIcon from './success.svg';
import ErrorIcon from './error.svg';
import InfoIcon from './info.svg';
import st from './styles.module.css';

// eslint-disable-next-line no-shadow
enum ClaimStatusEnum {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export const ClaimInfo = observer(() => {
  const { address, connect, isConnected } = useEvmWallet();
  const [userAddress, setUserAddress] = useState<string | undefined>(undefined);
  const { claimableAmount, allocationAmount, claimedAmount, availableAmount, decimals, symbol, scheduleIdsAndAmounts } =
    useClaimDetails(userAddress);
  const [claimStatus, setClaimStatus] = useState<any>(null);
  const [claimErrorText, setClaimErrorText] = useState<any | null>(null);

  useEffect(() => {
    if (!address) return;
    setUserAddress(address);
  }, [address]);

  const handleClaim = () => {
    if (!isConnected) {
      connect();
    } else if (!claimStatus) {
      setClaimStatus(ClaimStatusEnum.Pending);
      claimTokens(scheduleIdsAndAmounts)
        .then(({ isSuccess, error }) => {
          if (error) setClaimErrorText(error);
          setClaimStatus(isSuccess ? ClaimStatusEnum.Success : ClaimStatusEnum.Error);
        })
        .catch((error) => {
          setClaimErrorText(error);
          setClaimStatus(ClaimStatusEnum.Error);
        })
        .finally(() => {
          setUserAddress(undefined);
          setTimeout(() => setUserAddress(address), 500);
        });
    }
  };

  const renderClaimButtonText = () => {
    if (!isConnected) {
      return (
        <>
          Connect Wallet <img src={MMIcon} alt="" />
        </>
      );
    }
    if (claimStatus === ClaimStatusEnum.Pending) {
      return (
        <>
          Processing <img src={PendingIcon} alt="" />
        </>
      );
    }
    if (claimStatus === ClaimStatusEnum.Success) {
      return (
        <>
          Success <img src={SuccessIcon} alt="" />
        </>
      );
    }
    if (claimStatus === ClaimStatusEnum.Error) {
      return 'Fail';
    }
    return 'Claim';
  };

  const handleCopyErrorText = (event: any) => {
    event?.preventDefault();
    navigator.clipboard.writeText(event.target.textContent);
  };

  return (
    <>
      <div className={st.wrapper}>
        <div className={st['token-image-wrapper']}>
          <img className={st['token-image']} src={TokenImage} alt="token azy" />
        </div>
        <div className={st['token-claim']}>
          <span className={st['token-claim-title']}>Claimable Amount</span>
          <span className={st['token-claim-amount']}>
            {claimableAmount.shiftedBy(-decimals).toFixed(0)} {symbol}
          </span>
          <button
            type="button"
            onClick={handleClaim}
            disabled={
              (isConnected && claimableAmount.shiftedBy(-decimals).isLessThan(1)) ||
              claimStatus === ClaimStatusEnum.Pending
            }
            className={`${claimStatus === ClaimStatusEnum.Error ? st.error : ''} ${
              claimStatus === ClaimStatusEnum.Pending ? st.pending : ''
            }`}
          >
            {renderClaimButtonText()}
          </button>
        </div>
        <div className={st['token-claim-description']}>
          <img src={claimErrorText ? ErrorIcon : InfoIcon} alt="" />
          {claimErrorText && (
            // eslint-disable-next-line
            <p onClick={handleCopyErrorText} className={st['claim-error-text']}>
              {claimErrorText}
            </p>
          )}
          {!claimErrorText && (
            <p>
              If you have available for claim tokens in several vesting rounds, then you will need to sign several
              blockchain transactions
            </p>
          )}
        </div>
      </div>
      <div className={st.allocation}>
        <AmountInfo
          text="Allocation Amount"
          amount={allocationAmount.shiftedBy(-decimals).toFixed(2)}
          symbol={symbol}
        />
        <AmountInfo text="Claimed Amont" amount={claimedAmount.shiftedBy(-decimals).toFixed(2)} symbol={symbol} />
        <AmountInfo text="Available Amount" amount={availableAmount.shiftedBy(-decimals).toFixed(2)} symbol={symbol} />
      </div>
    </>
  );
});
