import React from 'react';
import Icon from './icon.svg';
import st from './styles.module.css';

type AmountInfoProps = {
  text: string;
  amount: any;
  symbol: string;
};

export const AmountInfo = ({ text, amount, symbol }: AmountInfoProps) => (
  <div className={st.wrapper}>
    <img src={Icon} alt="" />
    <div>
      <span className={st.text}>{text}</span>
      <span className={st.amount}>
        {amount} {symbol}
      </span>
    </div>
  </div>
);
