import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import BigNumber from 'bignumber.js';
import erc20Abi from '../../../../abi/erc20/erc20.abi.json';
import TokenVesting from '../../../../abi/erc20/TokenVesting.abi.json';
import { useEvmWallet } from '../../../../services/EvmWalletService';

const contractAddress = '0xaf6cB77b9AF87aDee50f2e17B4AB5271dA531697';

type VestingDetails = {
  amountTotal: string;
  beneficiary: string;
  cliff: string;
  duration: string;
  initialized: boolean;
  released: string;
  revocable: boolean;
  revoked: boolean;
  slicePeriodSeconds: string;
  start: string;
};

const initialVestingDetailsState = {
  claimableAmount: new BigNumber(0),
  allocationAmount: new BigNumber(0),
  claimedAmount: new BigNumber(0),
  availableAmount: new BigNumber(0),
};

const initialTokenDetailsState = {
  decimals: 0,
  symbol: '',
};

const computeVestingDetails = (
  vestingDetailsList: { claimableAmount: string; claimedAmount: string; availableAmount: string }[]
) =>
  vestingDetailsList.reduce(
    (accum, vesting) => ({
      claimableAmount: accum.claimableAmount.plus(new BigNumber(vesting.claimableAmount)),
      allocationAmount: accum.allocationAmount.plus(
        new BigNumber(vesting.availableAmount).minus(new BigNumber(vesting.claimedAmount))
      ),
      claimedAmount: accum.claimedAmount.plus(new BigNumber(vesting.claimedAmount)),
      availableAmount: accum.availableAmount.plus(new BigNumber(vesting.availableAmount)),
    }),
    initialVestingDetailsState
  );

const getTokenDetails = async (tokenAddress: string, web3: Web3): Promise<typeof initialTokenDetailsState> => {
  const contract = new web3.eth.Contract(erc20Abi as unknown as AbiItem, tokenAddress);
  if (!contract) return initialTokenDetailsState;

  const decimals = await contract.methods.decimals().call();
  if (!decimals) return initialTokenDetailsState;

  const symbol = await contract.methods.symbol().call();
  if (!symbol) return initialTokenDetailsState;

  return { decimals, symbol };
};

const getClaimDetails = async (account: string) => {
  try {
    const { web3 } = useEvmWallet();
    if (!web3) throw Error('No Web3');

    const contract = new web3.eth.Contract(TokenVesting as unknown as AbiItem, contractAddress);
    if (!contract) throw Error('No contract');

    const {
      getVestingSchedulesCountByBeneficiary,
      computeVestingScheduleIdForAddressAndIndex,
      computeReleasableAmount,
      getVestingSchedule,
      getToken,
    } = contract.methods;

    /** Count of vesting schedules of Beneficiary */
    const schedulesCount: string = await getVestingSchedulesCountByBeneficiary(account).call();
    if (Number(schedulesCount) <= 0) throw Error('No schedules count');

    /** Prepare empty array with length of schedules count */
    const promiseArray: any[] = [];
    promiseArray.length = Number(schedulesCount);
    promiseArray.fill(null);

    /** Map the empty array and call contract method to get scheduled vesting ids */
    let vestingScheduleIds: string[] = await Promise.all(
      promiseArray.map((_, idx) => computeVestingScheduleIdForAddressAndIndex(account, idx).call())
    );

    /** Map scheduled vesting ids and call contract method to get vesting period details */
    let vestingDetailsList: VestingDetails[] = await Promise.all(
      vestingScheduleIds.map((id) => getVestingSchedule(id).call())
    );

    vestingScheduleIds = vestingScheduleIds.filter((_, idx) => !vestingDetailsList[idx].revoked);
    vestingDetailsList = vestingDetailsList.filter((vestingDetails) => !vestingDetails.revoked);

    /** Map scheduled vesting ids and call contract method to get computed releasable amount */
    const vestingReleasableList: any = await Promise.all(
      vestingScheduleIds.map((id) => computeReleasableAmount(id).call())
    );

    const combinedDetails = vestingDetailsList.map((vesting, idx) => ({
      claimableAmount: vestingReleasableList[idx],
      claimedAmount: vesting.released,
      availableAmount: vesting.amountTotal,
    }));

    const scheduleIdsAndAmounts = vestingScheduleIds.map((vestingScheduleId, idx) => ({
      vestingScheduleId,
      claimableAmount: vestingReleasableList[idx],
    }));
    const vestingDetails = computeVestingDetails(combinedDetails);
    const tokenDetails = await getTokenDetails(await getToken().call(), web3);

    return {
      ...vestingDetails,
      ...tokenDetails,
      scheduleIdsAndAmounts,
    };
  } catch {
    return {
      ...initialVestingDetailsState,
      ...initialTokenDetailsState,
      scheduleIdsAndAmounts: [],
    };
  }
};

export const claimTokens = async (
  vestingReleasableList: Array<{ vestingScheduleId: string; claimableAmount: string }>
) => {
  try {
    const { web3, address } = useEvmWallet();
    if (!web3) return { isSuccess: false, error: 'Web3 is undefined' };

    const contract = new web3.eth.Contract(TokenVesting as unknown as AbiItem, contractAddress);
    if (!contract) return { isSuccess: false, error: "Contract isn't initialized" };

    const { release } = contract.methods;

    await Promise.all(
      vestingReleasableList
        .filter(({ claimableAmount }) => claimableAmount !== '0')
        .map(({ vestingScheduleId, claimableAmount }) =>
          release(vestingScheduleId, claimableAmount).send({ from: address })
        )
    );

    return { isSuccess: true };
  } catch (error) {
    console.error('Token Claim Error', error);
    return { isSuccess: false, error };
  }
};

type claimDetailsState = typeof initialVestingDetailsState &
  typeof initialTokenDetailsState & {
    scheduleIdsAndAmounts: Array<{ vestingScheduleId: string; claimableAmount: string }>;
  };

export const useClaimDetails = (address?: string) => {
  const [claimDetails, setClaimDetails] = useState<claimDetailsState>({
    ...initialVestingDetailsState,
    ...initialTokenDetailsState,
    scheduleIdsAndAmounts: [],
  });

  useEffect(() => {
    if (!address) return;
    getClaimDetails(address).then(setClaimDetails);
  }, [address]);

  return claimDetails;
};
