/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { GeneralClaim } from '../general-claim/GeneralClaim';
import st from './style.module.css';

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [to]);
  return null;
};

const Main = (/* {}: MainProps */) => {
  return (
    <div className={`${st.box} ${st.mobile}`} style={{ minHeight: '100vh' }}>
      <section className={st.content} style={{ minHeight: '100vh' }}>
        <Routes>
          <Route path="/" element={<GeneralClaim />} />
          <Route path="*" element={<Redirect to="/" />} />
        </Routes>
      </section>
    </div>
  );
};

export default Main;
